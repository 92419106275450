import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { web3 } from '../../store/web3';
import '../../app/RoyalCasino/index.css';
import { networkId, message } from '../../store/config';
import { login, logout, setAddress } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            token: '',
            errorText: ''
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.setAddress(address);
            this.props.login(address);
            this.setState({ address });
        });
    };

    logout() {
        // Delete all items from local storage to logout the user
        localStorage.clear();
        this.props.logout('');
        this.setState({ token: '', address: '' });
    }

    render() {
        let { publicAddress } = this.props;
        return (
            <header>
                <div class="container">
                    <nav class="navbar">
                        <a href="/" class="logo" >
                            <img src="images/logo.png" alt="" />
                        </a>

                        <ul>
                            <li>
                                <a href="/casino-vending-machine" >
                                    Casino Vending
                                </a>
                            </li>

                            <li>
                                <a href="/vip-vending-machine" >
                                    VIP Vending
                                </a>
                            </li>

                            <li>
                                <a href="/Rooms" >
                                    Rooms
                                </a>
                            </li>
                        </ul>
                        

                        
                        
                        {
                            publicAddress == '' || publicAddress == null ?
                                <button onClick={this.connectWallet} className="common-btn" type="button">
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                                :
                                <button onClick={this.logout} className="common-btn" type="button">
                                    {publicAddress == '' || publicAddress == null
                                        ? 'Connect wallet'
                                        : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                    }
                                </button>
                        }
                    </nav>
                </div>
            </header>
        );
    }
}
const mapDispatchToProps = {
    login,
    logout,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);