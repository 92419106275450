import React, { Component } from 'react';

import '../../app/RoyalCasino/index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <footer>
                <div class="container">
                    <div class="copyright">
                        <p>Copyright © 2023 Royal casino. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;