const { web3 } = require('../web3');
const { env } = require('../config');

const TokenData = require(`./${env}/Token.json`);
const LotteryData = require(`./${env}/Lottery.json`);
const CasinoTokenData = require(`./${env}/CasinoToken.json`);
const VipTokenData = require(`./${env}/VipToken.json`);
const CasinoVendingData = require(`./${env}/CasinoVending.json`);
const VipVendingData = require(`./${env}/VipVending.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);

const LotteryABI = LotteryData['abi'];
const LotteryAddress = LotteryData['address'];
const Lottery = new web3.eth.Contract(LotteryABI, LotteryAddress);

const CasinoTokenABI = CasinoTokenData['abi'];
const CasinoTokenAddress = CasinoTokenData['address'];
const CasinoToken = new web3.eth.Contract(CasinoTokenABI, CasinoTokenAddress);

const VipTokenABI = VipTokenData['abi'];
const VipTokenAddress = VipTokenData['address'];
const VipToken = new web3.eth.Contract(VipTokenABI, VipTokenAddress);

const CasinoVendingABI = CasinoVendingData['abi'];
const CasinoVendingAddress = CasinoVendingData['address'];
const CasinoVending = new web3.eth.Contract(CasinoVendingABI, CasinoVendingAddress);

const VipVendingABI = VipVendingData['abi'];
const VipVendingAddress = VipVendingData['address'];
const VipVending = new web3.eth.Contract(VipVendingABI, VipVendingAddress);

module.exports = {
  Token, TokenABI, TokenAddress,
  Lottery, LotteryABI, LotteryAddress,
  CasinoToken, CasinoTokenABI, CasinoTokenAddress,
  VipToken, VipTokenABI, VipTokenAddress,
  CasinoVending, CasinoVendingABI, CasinoVendingAddress,
  VipVending, VipVendingABI, VipVendingAddress,
};
