export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const innerForm = (data) => ({
  type: 'INNER_FORM',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const getPokerRoom = (data) => ({
  type: 'GET_POKER_ROOM',
  payload: data,
});

export const setPokerRoom = (data) => ({
  type: 'SET_POKER_ROOM',
  payload: data,
});

export const getCasinoRoom = (data) => ({
  type: 'GET_CASINO_ROOM',
  payload: data,
});

export const setCasinoRoom = (data) => ({
  type: 'SET_CASINO_ROOM',
  payload: data,
});