/* -- set app title --*/
const AppTitle = 'Frontend Royal Casino';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://localhost:3000';
const production = 'https://www.server.multiverse.wcofans.com';
const development = 'https://www.server.multiverse.wcofans.com';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 80001;
    SocketUrl = development;
    explorer = 'https://mumbai.polygonscan.com/'
    message = 'Please switch your network to mumbai Testnet';
    break;
  case 'production':
    networkId = 137;
    SocketUrl = production;
    message = 'Please switch your network to mumbai Mainnet';
    explorer = 'https://polygonscan.com/'
    break;
  case 'testing':
    networkId = 80001;
    SocketUrl = testing;
    message = 'Please switch your network to mumbai testnet';
    explorer = 'https://mumbai.polygonscan.com/'
    break;
  default:
    networkId = 80001;
    SocketUrl = 'http://localhost:4000';
    message = 'Please switch your network to mumbai Testnet';
    explorer = 'https://mumbai.polygonscan.com/'
}
let ApiUrl = `${SocketUrl}/api/v1`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env };