import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setPokerRoom, setCasinoRoom, toggleLoader } from '../actions/Auth';

function* innerFormData({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/info', payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) EventBus.publish('success', response['data']['message']);
};

function* getPokerRoom() {
  const { error, response } = yield call(getCall, "/getPokerRoom");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setPokerRoom(response['data']['body']));
  }
};

function* getCasinoRoom() {
  const { error, response } = yield call(getCall, "/getCasinoRoom");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setCasinoRoom(response['data']['body']));
  }
};

function* actionWatcher() {
  yield takeEvery('INNER_FORM', innerFormData);
  yield takeEvery('GET_POKER_ROOM', getPokerRoom);
  yield takeEvery('GET_CASINO_ROOM', getCasinoRoom);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
