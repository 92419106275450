import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";

import './index.css';
import '../../static/css/animate.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { getCasinoRoom, getPokerRoom } from "../../store/actions/Auth";

const Rooms = (props) => {

    useEffect(() => {
        props.getPokerRoom();
        props.getCasinoRoom();
    }, [])

    async function copiedID() {
        EventBus.publish("success", "Room ID Copied");
    }

    let { pokerRooms, casinoRooms } = props;


    return (
        <div className="wrapper">
            <Navbar />
            <section class="casino-intro rooms-head">
                <div class="container">
                    <div class="text-center">
                        <h1 className="w-100">Rooms</h1>
                    </div>
                </div>
            </section>

            <section class="mint games">
                <div class="container">
                    <div class="cards-deck-images">
                        <img class="diamond" src="images/diamond.png" alt="" />

                        <img class="spades" src="images/spades.png" alt="" />

                        <img class="hearts" src="images/hearts.png" alt="" />
                    </div>

                    <h2>Poker Rooms</h2>

                    <div class="row">
                        {
                            pokerRooms.length > 0 &&
                            pokerRooms.map((item, idx) => (
                                <div class="col-md-4">
                                    <div class="game-box room" id="poker-room-1">
                                        <div class="head-wrap">
                                            <div class="img-wrap">
                                                <img src="images/poker.png" alt="" />
                                            </div>

                                            <div class="heading-wrap">
                                                <h3>Room {idx + 1}</h3>
                                            </div>
                                        </div>

                                        <div class="bottom">
                                            <span>Copy room ID to enter in poker</span>
                                            <CopyToClipboard text={item['id']} onCopy={() => copiedID()}>
                                                <span>
                                                    <a>
                                                        {item['id']}
                                                    </a>
                                                </span>
                                            </CopyToClipboard>
                                            <a href="http://casinoverse-meta-temp.s3-website.us-east-2.amazonaws.com" target="_blank">Enter</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <h2>Casino Rooms</h2>

                    <div class="row">
                    {
                            casinoRooms.length > 0 &&
                            casinoRooms.map((item, idx) => (
                                <div class="col-md-4">
                                    <div class="game-box room" id="poker-room-1">
                                        <div class="head-wrap">
                                            <div class="img-wrap">
                                                <img src="images/roulette.png" alt="" />
                                            </div>

                                            <div class="heading-wrap">
                                                <h3>Room {idx + 1}</h3>
                                            </div>
                                        </div>

                                        <div class="bottom">
                                            <span>Copy room ID to enter in casinoverse</span>
                                            <CopyToClipboard text={item['id']} onCopy={() => copiedID()}>
                                                <span>
                                                    <a>
                                                        {item['id']}
                                                    </a>
                                                </span>
                                            </CopyToClipboard>
                                            <a href="http://casinoverse-metaverse.s3-website.ap-south-1.amazonaws.com/" target="_blank">Enter</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { getCasinoRoom, getPokerRoom };

const mapStateToProps = ({ Auth }) => {
    let { casinoRooms, pokerRooms } = Auth;
    return { casinoRooms, pokerRooms }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);