import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader } from "../../store/actions/Auth";
import { Token, TokenAddress, Lottery, LotteryAddress } from '../../store/contract/index';

const RoyalCasino = (props) => {

    let [mintItems, setMintItems] = useState(10000);
    let [mintedItems, setMintedItems] = useState(0);
    let [balance, setBalance] = useState(0);
    let [txHash, setTxHash] = useState('');
    let [owned, setOwned] = useState(0);

    async function getContract() {
        try {
            let deployer = (await web3.currentProvider.enable())[0];
            let lBalance = await Lottery.methods.contractBalance().call({ from: deployer });
            lBalance = web3.utils.fromWei(lBalance, 'ether');
            lBalance = parseInt(lBalance);
            let minted = await Token.methods.totalSupply().call({ from: deployer });
            minted = parseInt(minted);
            let holdings = await Token.methods.balanceOf(deployer).call({ from: deployer });
            holdings = parseInt(holdings);
            let balanceWei = await web3.eth.getBalance(deployer);
            let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            balanceEther = parseInt(balanceEther);
            console.log("************ balanceEther", balanceEther);
            setMintedItems(minted);
            setOwned(holdings);
            setBalance(balanceEther);
        } catch (e) {
            console.log("*** error :: ", e);
        }
    }

    useEffect(() => {
        if (props.publicAddress !== null && props.publicAddress !== undefined && props.publicAddress !== "") {
            getContract();
        }
    }, [props.publicAddress])

    async function copiedHash() {
        EventBus.publish("success", "Transaction Hash Copied");
    }

    async function mintNFT() {
        try {

            let remaining = mintItems - mintedItems;

            if (1 > remaining) {
                EventBus.publish('error', `Quantity exceeds remaining NFT's`);
                return;
            };

            console.log("******** balance", balance);

            if (balance < 0.1) {
                EventBus.publish('error', `Your have insufficient MATIC Balance`);
                return;
            };

            if (owned > 0) {
                EventBus.publish('info', `You have already minted you NFT!`);
                return;
            };

            /** Mint NFT **/

            let deployer = (await web3.currentProvider.enable())[0];

            // let sentValue = 0.1;
            let sentValue = web3.utils.toWei("0.1", 'ether');

            props.toggleLoader({
                message: "Mint in Progress ...",
                status: true,
            });

            await web3.eth.sendTransaction({
                from: deployer,
                value: sentValue,
                to: LotteryAddress,
                data: Lottery.methods.enter(1).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    await web3.eth.sendTransaction({
                        from: deployer,
                        value: 0,
                        to: TokenAddress,
                        data: Token.methods.mint(deployer, 1).encodeABI(),
                    }).on('transactionHash', hash => {
                        console.log(`************** deploy contract hash = ${hash}`);
                        setTxHash(hash);
                    })
                        .on('receipt', async receipt => {
                            mintedItems = mintedItems + 1;
                            setMintedItems(mintedItems);
                            props.toggleLoader({ message: 'Minting Completed...', status: false });
                            EventBus.publish('success', `NFT minted successfully`);
                        })
                })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    console.log("************ publicAddress :: ", props.publicAddress);

    return (
        <div className="wrapper">
            <Navbar />
            <section class="casino-intro">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="content">
                                <h1>Dive into a World Where Every Spin Could Be Your Win!</h1>

                                <p>Meet CasinoVerse - where the future of online gaming is already a reality. Imagine a platform that harnesses the power of blockchain for unbeatable security, blended seamlessly with the charm of your favorite traditional casinos. Ready to embark on an epic gaming journey? The adventure starts with your first click.</p>

                                <a class="common-btn" href="">Play now</a>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div id="canvas3d">
                                <Spline scene="https://prod.spline.design/DZtny3SZ5RfglyjT/scene.splinecode" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mint">
                <div class="container">
                    <div class="cards-deck-images">
                        <img class="diamond" src="images/diamond.png" alt="" />

                        <img class="spades" src="images/spades.png" alt="" />

                        <img class="hearts" src="images/hearts.png" alt="" />
                    </div>

                    <div class="row">
                        <div class="col-lg-7">
                            <div class="content">
                                <h2>Mint your lottery ticket to get access to CasinoVerse</h2>
                            </div>
                        </div>

                        <div class="col-lg-5 nft">
                            <div class="info">
                                <span>Total number of NFT’s</span>
                                <span>{mintItems}</span>
                            </div>

                            <div class="info">
                                <span>Total minted NFT’s</span>
                                <span>{mintedItems}</span>
                            </div>

                            <div class="info">
                                <span>Price per NFT</span>
                                <span>0.1 MATIC</span>
                            </div>

                            <div class="info">
                                <span>Connected address</span>
                                <span>
                                    {props.publicAddress == '' || props.publicAddress == null
                                        ? 'Connect wallet'
                                        : props.publicAddress && props.publicAddress.substring(0, 5) + '.....' + props.publicAddress.substring(37, props.publicAddress.length)
                                    }
                                </span>
                            </div>

                            {
                                txHash !== '' &&
                                <div class="info">
                                    <span>Tx:</span>
                                    <CopyToClipboard text={txHash} onCopy={() => copiedHash()}>
                                        <a>
                                            {txHash == '' || txHash == null
                                                ? 'Transaction Hash'
                                                : txHash && txHash.substring(0, 5) + '.....' + txHash.substring(55, txHash.length)
                                            }
                                        </a>
                                    </CopyToClipboard>
                                </div>
                            }
                            <button type="submit" class="common-btn" onClick={() => mintNFT()}>
                                Mint now
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section class="games">
                <div class="container">
                    <div class="head">
                        <img src="images/clubs.png" alt="" />

                        <h2>Feel the Pulse of Every Game!</h2>

                        <p>Craving the rush of slots, the strategy of poker, or the thrill of a lucky draw? CasinoVerse ensures every moment you spend gaming is filled with adrenaline, excitement, and the anticipation of the next big win.</p>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="game-box">
                                <div class="head-wrap">
                                    <div class="img-wrap">
                                        <img src="images/poker.png" alt="" />
                                    </div>

                                    <div class="heading-wrap">
                                        <h3>Poker</h3>
                                    </div>
                                </div>

                                <div class="bottom">
                                    <a href="">Click to play</a>

                                    <img src="images/games-clubs.png" alt="" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="game-box">
                                <div class="head-wrap">
                                    <div class="img-wrap">
                                        <img src="images/black-jack.png" alt="" />
                                    </div>

                                    <div class="heading-wrap">
                                        <h3>Black jack</h3>
                                    </div>
                                </div>

                                <div class="bottom">
                                    <a href="">Click to play</a>

                                    <img src="images/games-hearts.png" alt="" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="game-box">
                                <div class="head-wrap">
                                    <div class="img-wrap">
                                        <img src="images/roulette.png" alt="" />
                                    </div>

                                    <div class="heading-wrap">
                                        <h3>Roulette</h3>
                                    </div>
                                </div>

                                <div class="bottom">
                                    <a href="">Click to play</a>

                                    <img src="images/games-spades.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="play-in-metaverse">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-5">
                            <div class="img-wrap">
                                <img src="images/metaverse.png" alt="" />
                            </div>
                        </div>

                        <div class="col-lg-7">
                            <div class="content">
                                <img src="images/casino-text.png" alt="" />

                                <h2>Gaming Just Got a Whole Lot More Real</h2>

                                <p>Ever dreamt of a world where gaming leaps off the screen and wraps around you? Welcome to CasinoVerse in the metaverse. Here, gaming isn't just about points or jackpots, but about forging connections, exploring new horizons, and discovering games that resonate with your spirit. Unleash the gamer within you and craft your own saga in a universe where anything is possible.</p>

                                <a class="common-btn" href="http://casinoverse-metaverse.s3-website.ap-south-1.amazonaws.com/">Play now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoyalCasino);