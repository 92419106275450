import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader } from "../../store/actions/Auth";
import { Token, TokenAddress, Lottery, LotteryAddress } from '../../store/contract/index';

const Rooms = (props) => {

    let [mintItems, setMintItems] = useState(10000);
    let [mintedItems, setMintedItems] = useState(0);
    let [balance, setBalance] = useState(0);
    let [txHash, setTxHash] = useState('');
    let [owned, setOwned] = useState(0);

    async function getContract() {
        try {
            let deployer = (await web3.currentProvider.enable())[0];
            let lBalance = await Lottery.methods.contractBalance().call({ from: deployer });
            lBalance = web3.utils.fromWei(lBalance, 'ether');
            lBalance = parseInt(lBalance);
            let minted = await Token.methods.totalSupply().call({ from: deployer });
            minted = parseInt(minted);
            let holdings = await Token.methods.balanceOf(deployer).call({ from: deployer });
            holdings = parseInt(holdings);
            let balanceWei = await web3.eth.getBalance(deployer);
            let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            balanceEther = parseInt(balanceEther);
            console.log("************ balanceEther", balanceEther);
            setMintedItems(minted);
            setOwned(holdings);
            setBalance(balanceEther);
        } catch (e) {
            console.log("*** error :: ", e);
        }
    }

    useEffect(() => {
        if (props.publicAddress !== null && props.publicAddress !== undefined && props.publicAddress !== "") {
            getContract();
        }
    }, [props.publicAddress])

    async function copiedHash() {
        EventBus.publish("success", "Transaction Hash Copied");
    }

    async function mintNFT() {
        try {

            let remaining = mintItems - mintedItems;

            if (1 > remaining) {
                EventBus.publish('error', `Quantity exceeds remaining NFT's`);
                return;
            };

            console.log("******** balance", balance);

            if (balance < 0.1) {
                EventBus.publish('error', `Your have insufficient MATIC Balance`);
                return;
            };

            if (owned > 0) {
                EventBus.publish('info', `You have already minted you NFT!`);
                return;
            };

            /** Mint NFT **/

            let deployer = (await web3.currentProvider.enable())[0];

            // let sentValue = 0.1;
            let sentValue = web3.utils.toWei("0.1", 'ether');

            props.toggleLoader({
                message: "Mint in Progress ...",
                status: true,
            });

            await web3.eth.sendTransaction({
                from: deployer,
                value: sentValue,
                to: LotteryAddress,
                data: Lottery.methods.enter(1).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    await web3.eth.sendTransaction({
                        from: deployer,
                        value: 0,
                        to: TokenAddress,
                        data: Token.methods.mint(deployer, 1).encodeABI(),
                    }).on('transactionHash', hash => {
                        console.log(`************** deploy contract hash = ${hash}`);
                        setTxHash(hash);
                    })
                        .on('receipt', async receipt => {
                            mintedItems = mintedItems + 1;
                            setMintedItems(mintedItems);
                            props.toggleLoader({ message: 'Minting Completed...', status: false });
                            EventBus.publish('success', `NFT minted successfully`);
                        })
                })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    console.log("************ publicAddress :: ", props.publicAddress);

    return (
        <div className="wrapper">
            <Navbar />
            <section class="casino-intro">
                <div class="container">
                    <div className="leaderboard-wrap">
                        <div className="leaderboard">
                            <div className="inner">
                                <div className="head">
                                    <img src="images/clubs-blue.png" alt="" />
                                    <h2>Leaderboard</h2>
                                    <img src="images/spades-blue.png" alt="" />
                                </div>

                                <div className="table-wrap">
                                    <table responsive="md">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Amount</th>
                                                <th>Winner</th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            <tr>
                                                <td>Weekly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>

                                            <tr>
                                                <td>Yearly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>

                                            <tr>
                                                <td>Quarterly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>

                                            <tr>
                                                <td>Weekly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>

                                            <tr>
                                                <td>Yearly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>

                                            <tr>
                                                <td>Quarterly</td>
                                                <td>358932</td>
                                                <td>0x12r45...6HJ9</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <Footer />
        </div >
    );
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);