import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import copy from "../../static/images/copy.png";
import goarrow from "../../static/images/go-arrow.png";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { networkId } from "../../store/config";
import { toggleLoader } from "../../store/actions/Auth";
import { CasinoToken, CasinoVendingAddress, CasinoVending, Token } from '../../store/contract/index';

const CasinoVendingMachine = (props) => {

    let [casinoBalance, setCasinoBalance] = useState(0);
    let [casinoSymbol, setCasinoSymbol] = useState("");
    let [casinoAmount, setcasinoAmount] = useState("");
    let [xdcAmount, setxdcAmount] = useState("");
    let [balance, setBalance] = useState(0);
    let [txHash, setTxHash] = useState('');
    let [owned, setOwned] = useState(0);
    let [price, setPrice] = useState(0);

    async function getContract() {
        try {
            let deployer = (await web3.currentProvider.enable())[0];
            let holdings = await Token.methods.balanceOf(deployer).call({ from: deployer });
            holdings = parseInt(holdings);
            let priceCasino = await CasinoVending.methods.price().call({ from: deployer });
            priceCasino = web3.utils.fromWei(priceCasino, 'ether');
            priceCasino = parseFloat(priceCasino);
            let symbol = await CasinoToken.methods.symbol().call({ from: deployer });
            let yourBalance = await CasinoToken.methods.balanceOf(deployer).call({ from: deployer });
            yourBalance = web3.utils.fromWei(yourBalance, 'ether');
            yourBalance = parseFloat(yourBalance);
            let balanceWei = await web3.eth.getBalance(deployer);
            let balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            balanceEther = parseInt(balanceEther);
            setOwned(holdings);
            setPrice(priceCasino);
            setCasinoSymbol(symbol);
            setBalance(balanceEther);
            setCasinoBalance(yourBalance);
        } catch (e) {
            console.log("*** error :: ", e);
        }
    }

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "casinoAmount") {
            if (parseFloat(e.target.value) >= price) {
                setcasinoAmount(parseFloat(e.target.value));
                setxdcAmount(parseFloat(e.target.value) * price);
            } else {
                setcasinoAmount("");
                setxdcAmount("");
            }
        }
    }

    useEffect(() => {
        if (props.publicAddress !== null && props.publicAddress !== undefined && props.publicAddress !== "") {
            getContract();
        }
    }, [props.publicAddress])

    async function swapCasino(e) {
        try {
            e.preventDefault();

            if (casinoAmount == "") {
                EventBus.publish("error", `Enter amount!`);
                return;
            }

            let { publicAddress } = props;

            if (balance < price) {
                EventBus.publish('error', `Your have insufficient MATIC Balance`);
                return;
            };

            if (owned <= 0) {
                EventBus.publish('info', `You need to mint NFT first!`);
                return;
            };

            /** Mint NFT **/

            let deployer = (await web3.currentProvider.enable())[0];

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            // let sentValue = 0.1;
            let newXdcAmount = web3.utils.toWei(xdcAmount.toString(), 'ether');
            let newCasinoAmount = web3.utils.toWei(casinoAmount.toString(), 'ether');

            props.toggleLoader({
                message: "Swap in Progress ...",
                status: true,
            });

            await web3.eth.sendTransaction({
                from: deployer,
                value: newXdcAmount,
                to: CasinoVendingAddress,
                data: CasinoVending.methods.swapTokens(deployer, newCasinoAmount).encodeABI(),
            }).on('transactionHash', hash => {
                console.log(`************** deploy contract hash = ${hash}`);
                setTxHash(hash);
            }).on('receipt', async receipt => {
                let yourBalance = await CasinoToken.methods.balanceOf(deployer).call({ from: deployer });
                yourBalance = web3.utils.fromWei(yourBalance, 'ether');
                yourBalance = parseFloat(yourBalance);
                setCasinoBalance(yourBalance);
                props.toggleLoader({ message: 'Minting Completed...', status: false });
                EventBus.publish('success', `Casino tokens swapped successfully`);
            })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to swap casino tokens!`);
        };
    };

    async function copiedAddress() {
        EventBus.publish("success", "Transaction Hash Copied");
    }

    const handleCopyText = () => {
        navigator.clipboard.writeText(txHash)
            .then(() => EventBus.publish("success", "Transaction Hash Copied"))
            .catch((error) => console.log('Copy failed:', error));
    };

    return (
        <div className="wrapper">
            <Navbar />

            <section class="mint vending">
                <div class="container">
                    <div class="cards-deck-images">
                        <img class="diamond" src="images/diamond.png" alt="" />

                        <img class="spades" src="images/spades.png" alt="" />

                        <img class="hearts" src="images/hearts.png" alt="" />
                    </div>

                    <div class="row">
                        <div class="col-lg-7">
                            <div class="content">
                                <h2>Casino Vending Machine</h2>

                                <p>Experience the agility and fluidity of gambling within the Casinoverse. With Casinoverse Swap, diversifying assets becomes more than a transaction—it's an adventure. Effortlessly swap, trade, and transcend the ordinary.</p>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            {
                                txHash !== "" &&
                                <div className="form-group hash">
                                    <p>
                                        <CopyToClipboard
                                            text={txHash}
                                            onCopy={copiedAddress}
                                        >
                                            {networkId == 137 ? (
                                                <a href={`https://polygonscan.com/tx/k${txHash}`} target="_blank">
                                                    {`Transaction Hash:`}

                                                    <span>
                                                        <img className="copy" src={copy} alt="Tick Circle" onClick={() => handleCopyText()} />
                                                            {txHash}
                                                        <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                                                    </span>
                                                    
                                                </a>
                                            ) : networkId == 80001 ? (
                                                <a href={`https://mumbai.polygonscan.com/tx/${txHash}`} target="_blank">
                                                    {`Transaction Hash:`}
                                                    <span>
                                                        <img className="copy" src={copy} alt="Tick Circle" onClick={() => handleCopyText()} />
                                                            {txHash}
                                                        <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                                                    </span>
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </CopyToClipboard>
                                        
                                    </p>
                                </div>
                            }

                            <form className="nft" onSubmit={swapCasino}>
                                <div className="form-group">
                                    <input type="number" name="casinoAmount" value={casinoAmount} onChange={handleOnInput} placeholder="Casino Amount" />
                                </div>

                                <img className="swap" src="images/arrow-swap.png" alt="" />

                                <div className="form-group">
                                    <input type="number" name="xdcAmount" value={xdcAmount} placeholder="XDC Amount" disabled />
                                </div>

                                {
                                    casinoBalance !== 0 &&
                                    <div class="info">
                                        <span>Casino Token Balance</span>
                                        <span>{casinoBalance} {casinoSymbol}</span>
                                    </div>
                                }

                                <button type="submit" class="common-btn">
                                    Swap
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(CasinoVendingMachine);