import { PURGE } from "redux-persist";

let initialState = {
  publicAddress: localStorage.getItem("publicAddress"),
  setLoader: { message: 'Please Wait...', status: false },
  pokerRooms: [],
  casinoRooms: [],
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'LOGIN':
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem("publicAddress");
      return {
        ...state,
        publicAddress: '',
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_POKER_ROOM':
      return {
        ...state,
        pokerRooms: payload,
      };

    case 'SET_CASINO_ROOM':
      return {
        ...state,
        casinoRooms: payload,
      };

    case 'SET_ADDRESS':
      localStorage.setItem("publicAddress", payload);
      return {
        ...state,
        publicAddress: payload,
      };

    default:
      return state;
  }
};

export default Auth;